import { useState } from "react";
import UserProfile from "./Profile";
import WorkspaceSetting from "./WokspaceManageMent";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Tabs } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import WorkspaceList from "./WorksSpaceList";
const Setting = () => {
    const [tabvalue, setTabValue] = useState("1");
    const handleChange = () => {
        setTabValue(() => (tabvalue === "2" ? "1" : "2"));
    };
    return (
        <Box sx={{ marginLeft: "-100px" }}>
            <TabContext value={tabvalue}>
                <Tabs
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="full width tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                >
                    <Tab
                        label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <AccountCircleIcon sx={{ margin: 1 }} />
                                Profile
                            </Box>
                        }
                        value="1"
                    />
                    <Tab
                        label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <WorkHistoryIcon sx={{ margin: 1 }} />
                                WorkSpace
                            </Box>
                        }
                        value="2"
                    />
                </Tabs>
                <TabPanel value="1">
                    <UserProfile />
                </TabPanel>
                <TabPanel value="2">
                    <WorkspaceList />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
export default Setting;
