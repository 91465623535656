import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import Paper from "@mui/material/Paper";
import { Typography, Skeleton, Pagination } from "@mui/material";
import { format } from "date-fns";
import WorkspaceManagement from "./WokspaceManageMent";

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function WorkspaceList() {
    const [workspaceList, setWorkSpaceList] = useState([]);
    const [showSkeleton, setShowSkelton] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showWorkSpace, setShowWorkSpace] = useState(false);
    const [workSpaceData, setWorkSpaceData] = useState(null);
    const workspacesPerPage = 10;
    useEffect(() => {
        fetch(`${REACT_APP_SERVER_URL}/fetchAllWorkspaces`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.data) {
                    const sortedWorkspaces = data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setWorkSpaceList(sortedWorkspaces);
                }
                setShowSkelton(false);
            });
    }, []);

    const indexOfLastWorkspace = currentPage * workspacesPerPage;
    const indexOfFirstWorkspace = indexOfLastWorkspace - workspacesPerPage;
    const currentWorkspaces = workspaceList.slice(indexOfFirstWorkspace, indexOfLastWorkspace);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, "do MMM, HH:mm"); // Outputs like "8th Oct, 10:00"
    };
    const handleWorkSpace = (workspace) => {
        setShowWorkSpace(true);
        setWorkSpaceData(workspace);
    };
    return (
        <Box sx={{ flexGrow: 1, width: "100%", padding: 2 }}>
            {!showWorkSpace ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {showSkeleton ? (
                            <List>
                                {Array.from(new Array(6)).map((_, index) => (
                                    <Paper
                                        key={index}
                                        elevation={2}
                                        sx={{
                                            marginBottom: 2, // Space between list items
                                            backgroundColor: "#ffffff", // White background
                                            width: "100%", // Full width
                                            borderRadius: 1, // Rounded corners
                                            padding: 1,
                                        }}
                                    >
                                        <ListItem sx={{ width: "100%" }}>
                                            <ListItemIcon>
                                                <Skeleton variant="circular" width={40} height={40} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Skeleton variant="text" width="80%" height={20} />}
                                            />
                                        </ListItem>
                                    </Paper>
                                ))}
                            </List>
                        ) : workspaceList.length > 0 ? (
                            <>
                                <List>
                                    {currentWorkspaces.map((workspace) => (
                                        <Paper
                                            key={workspace.id}
                                            elevation={2}
                                            sx={{
                                                marginBottom: 2, // Space between list items
                                                backgroundColor: "#ffffff", // White background
                                                width: "100%", // Full width
                                                borderRadius: 1, // Rounded corners
                                            }}
                                        >
                                            <ListItem
                                                sx={{
                                                    width: "100%",
                                                    "&:hover": {
                                                        // Add hover styles here
                                                        backgroundColor: "#f0f0f0", // Change background color on hover
                                                        cursor: "pointer", // Set cursor to pointer
                                                    },
                                                }}
                                                onClick={() => handleWorkSpace(workspace)}
                                            >
                                                <ListItemIcon>
                                                    <FolderIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={workspace.name}
                                                    secondary={
                                                        <span>
                                                            <i>Created at : </i>
                                                            {formatDate(workspace.createdAt)}
                                                        </span>
                                                    }
                                                    secondaryTypographyProps={{ align: "right" }}
                                                />
                                            </ListItem>
                                        </Paper>
                                    ))}
                                </List>
                                <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                                    {workspaceList.length > 10 && (
                                        <Pagination
                                            count={Math.ceil(workspaceList.length / workspacesPerPage)}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            color="primary"
                                            variant="outlined"
                                        />
                                    )}
                                </Box>
                            </>
                        ) : (
                            <Typography>No Workspaces Found</Typography>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <WorkspaceManagement
                    workSpaceData={workSpaceData}
                    handleCloseWorkSpace={() => setShowWorkSpace(false)}
                />
            )}
        </Box>
    );
}
