import React, { useContext, useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    Box,
    IconButton,
    List,
    ListItem,
    Drawer as MuiDrawer,
    Divider,
    Container,
    MenuItem,
    Menu,
    Stack,
    Button,
    ListItemText,
    Chip,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import tools from "../../img/icons/tools.png";
import email1 from "../../img/icons/email1.png";
import domain from "../../img/icons/domain.png";
import blacklist from "../../img/icons/blacklist.png";
import records from "../../img/icons/records.png";
import spam from "../../img/icons/spam.png";
import avtar from "../../img/icons/avtar.png";
import blueemail from "../../img/icons/blueemail.png";
import bluedomain from "../../img/icons/bluedomain.png";
import blueblacklist from "../../img/icons/blueblacklist.png";
import bluedns from "../../img/icons/bluedns.png";
import bluespam from "../../img/icons/bluespam.png";
import bluetool from "../../img/icons/bluetool.png";
import bluecal from "../../img/icons/bluecal.png";
import globe from "../../img/icons/globe.png";
import cal from "../../img/icons/cal.png";
import emailaudit from "../../img/icons/email_audit.png";
import logo1 from "../../img/icons/logo1.png";
import cookie from "cookiejs";
import accountSetting from "../../img/icons/accountSetting.png";
import blueaccountSetting from "../../img/icons/blueaccountSetting.png";
import { AuthContext } from "../Provider/AuthProvider";
import bluespamwatch from "../../img/icons/bluespamwatch.png";
import spamwatch from "../../img/icons/spamwatch.png";
import AccountCircle from "@mui/icons-material/AccountCircle"; // Placeholder for workspace icon
import SnackBar from "../common/SnackBar";
const drawerWidth = 244;

const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));
const mdTheme = createTheme({
    typography: {
        fontFamily: ["League Spartan", "sans-serif"].join(","),
    },
});

const DashBoard = () => {
    const navigate = useNavigate();
    const ctx = useContext(AuthContext);
    const theme = useTheme();
    const [newWorkspaceName, setNewWorkspaceName] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [fname, setFName] = useState("");
    const [lname, setLName] = useState("");
    const [open, setOpen] = React.useState(true);
    const [isadmin, setAdmin] = React.useState("");
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(null);
    const [openDialogWorkSpace, setOpenDialogWorkspace] = useState(false);
    const [email, setEmail] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleSaveClick = () => {
        if (!newWorkspaceName && newWorkspaceName.trim().length < 5) {
            setOpenSnackbar({ message: "Invalid WorkSpace Name", type: "error" });
            setNewWorkspaceName("");
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/createworkspace`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                name: newWorkspaceName,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setOpenSnackbar({ message: data.message, type: "success" });
                } else {
                    setNewWorkspaceName("");
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
                setOpenDialogWorkspace(false);
            });
    };
    useEffect(() => {
        let isLogin = cookie.get("token");
        if (!isLogin) navigate("/login");
        else {
            setAdmin(ctx.user.admin);
            setFName(ctx.user.firstname || ctx.user.first_name || ctx.user.first_name);
            setLName(ctx.user.lastname || ctx.user.last_name || ctx.user.last_name);
            setEmail(ctx.user.email);
        }
    }, [navigate, ctx]);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuOpen(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileMenuOpen(event.currentTarget);
    };
    const handleLogoutAPI = (e) => {
        e.preventDefault();
        fetch(`${REACT_APP_SERVER_URL}/logout`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        }).then((response) => {
            if (response.status === 200) {
                console.log("User Logged out");
                navigate("/login");
                return response.json();
            } else {
                console.log("Some error");
            }
        });
    };
    const navLinks = [
        {
            path: "InboxPlacement",
            label: "Inbox Placement",
            defaultIcon: email1,
            activeIcon: blueemail,
        },
        {
            path: "manualAudit",
            label: "Manual Audit",
            defaultIcon: records,
            activeIcon: bluedns,
        },

        {
            path: "DomainReputation",
            label: "Domain Reputation",
            defaultIcon: domain,
            activeIcon: bluedomain,
        },
        {
            path: "IpBlacklist",
            label: "Blacklist Checker",
            defaultIcon: blacklist,
            activeIcon: blueblacklist,
        },
        {
            path: "DnsRecords",
            label: "DNS Records",
            defaultIcon: globe,
            activeIcon: logo1,
        },
        {
            path: "spammy-word-checker",
            label: "Spam Checker",
            defaultIcon: spam,
            activeIcon: bluespam,
        },
        {
            path: "DomainCalculator",
            label: "Cold Email Strategy",
            defaultIcon: cal,
            activeIcon: bluecal,
        },
        {
            path: "extra-tools",
            label: "Tools",
            defaultIcon: tools,
            activeIcon: bluetool,
        },
        {
            path: "find-ESP",
            label: "ESP Finder",
            defaultIcon: records,
            activeIcon: bluedns,
        },
        {
            path: "spreadsheet",
            label: "Spam Watch",
            defaultIcon: spamwatch,
            activeIcon: bluespamwatch,
        },
        {
            path: "integration",
            label: "Integration",
            defaultIcon: records,
            activeIcon: bluedns,
        },
        {
            path: "setting",
            label: "Settings",
            defaultIcon: accountSetting,
            activeIcon: blueaccountSetting,
        },
    ];
    const [anchorEl, setAnchorEl] = useState(null);
    const openAnchor = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [activeMenu, setActiveMenu] = useState(0);
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    open={open}
                    sx={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                        boxShadow: "none",
                        marginTop: "-2px",
                    }}
                >
                    <Toolbar
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            boxShadow: "none",
                        }}
                    >
                        {!open && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                sx={{
                                    marginLeft: "0!important",
                                    color: "black!important",
                                    ...(open && { display: "none" }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {open && (
                            <IconButton
                                sx={{
                                    marginRight: 5,
                                }}
                                onClick={() => {
                                    handleDrawerClose();
                                }}
                            >
                                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "84%",
                                backgroundColor: "white",
                                margin: "5px",
                                overflow: "hidden",
                            }}
                        >
                            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                                <IconButton
                                    variant="outlined"
                                    onClick={handleProfileMenuOpen}
                                    sx={{
                                        mx: 1,
                                        mb: 1,
                                        padding: "8px",
                                        border: "1px solid #e7e7e7",
                                        borderRadius: "6px",
                                        color: "#4F4F4F",
                                        height: "43px",
                                        marginTop: "5px",
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <img
                                            src={avtar}
                                            alt="profile-img"
                                            width="32px"
                                            style={{ borderRadius: "50%" }}
                                        />
                                        <Typography
                                            sx={{
                                                color: "#4F4F4F",
                                                fontSize: "14px",
                                                fontFamily: "'HK Nova', sans-serif",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {isadmin ? <h>Admin</h> : fname}
                                        </Typography>
                                    </Stack>
                                </IconButton>
                                <Box sx={{ float: "right" }}>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={profileMenuOpen}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                        open={Boolean(profileMenuOpen)}
                                        onClose={handleProfileMenuClose}
                                        style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                                    >
                                        <MenuItem
                                            sx={{
                                                width: "100%",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-evenly",
                                                    borderbottom: "1px solid Graytext",
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        src={avtar}
                                                        alt="profile-img"
                                                        width="32px"
                                                        style={{ borderRadius: "50%" }}
                                                    />
                                                </div>
                                                <div style={{ padding: "18px", marginTop: "-20px" }}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 600,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : fname}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            lineHeight: "20px",
                                                        }}
                                                    >
                                                        {isadmin ? <h>Admin</h> : email}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <div style={{ width: "auto" }}>
                                            <MenuItem onClick={handleLogoutAPI}>
                                                <LogoutIcon
                                                    sx={{
                                                        width: "20px",
                                                        height: "20px",
                                                        color: "#4F4F4F",
                                                        marginRight: "4px",
                                                    }}
                                                />{" "}
                                                <Typography
                                                    sx={{
                                                        ml: "12px",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        color: "#4F4F4F",
                                                        lineHeight: "20px",
                                                    }}
                                                >
                                                    Logout{" "}
                                                </Typography>
                                            </MenuItem>
                                        </div>
                                    </Menu>
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                    style={{
                        boxshadow:
                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                    }}
                >
                    <DrawerHeader
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            boxshadow:
                                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                        }}
                    >
                        <Box sx={{ paddingRight: "10%" }}>
                            <Link to="/">
                                <img
                                    src={emailaudit}
                                    alt="img"
                                    style={{
                                        height: "50px",
                                        width: "195px",
                                        top: "0",
                                        boxshadow:
                                            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)}",
                                        marginLeft: "20px",
                                    }}
                                />
                            </Link>
                        </Box>
                    </DrawerHeader>
                    <Divider />

                    <List sx={{ pl: 2, fontFamily: `'Inter', sans-serif` }}>
                        <ListItem>
                            <Button
                                startIcon={<AccountCircle />}
                                onClick={handleMenuOpen}
                                sx={{
                                    color: "#4F4F4F", // Text color
                                    borderColor: "#4F4F4F", // Border color
                                }}
                                endIcon={<ArrowDropDownIcon />}
                            >
                                {fname}'s Workspace
                            </Button>

                            <Menu
                                anchorEl={anchorEl}
                                open={openAnchor}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <MenuItem onClick={handleMenuClose}>
                                    <ListItemText primary="Workspace settings" />
                                </MenuItem>

                                <Divider />

                                <MenuItem onClick={handleMenuClose}>
                                    <Box display="flex" flexDirection="column">
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                            {fname}'s Workspace
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            Free Trial + 1 member
                                        </Typography>
                                    </Box>
                                    <Chip
                                        label="Owner"
                                        color="primary"
                                        size="small"
                                        sx={{ marginLeft: "auto", backgroundColor: "#e0e0e0", color: "#4F4F4F" }} // Custom styles
                                    />
                                </MenuItem>

                                <MenuItem onClick={handleMenuClose}>
                                    <ListItemText
                                        primary="Add workspace"
                                        onClick={() => setOpenDialogWorkspace(true)}
                                    />
                                </MenuItem>
                            </Menu>
                        </ListItem>
                        {navLinks.map((link, index) => (
                            <NavLink
                                exact
                                to={link.path}
                                style={{ textDecoration: "none", color: "#4F4F4F" }}
                                key={link.path}
                            >
                                <ListItem
                                    onClick={() => {
                                        setActiveMenu(index);
                                    }}
                                    sx={{
                                        backgroundColor: activeMenu === index ? "#F7F7F9" : "none",
                                        borderRadius: "50px 0px 0px 50px",
                                        borderRight: activeMenu === index ? "4px solid #7953FF" : "none",
                                        width: "100%",
                                        mb: 2,
                                        fontSize: "13px",
                                        color: activeMenu === index ? "blue!important" : "none",
                                    }}
                                >
                                    <img
                                        src={activeMenu === index ? link.activeIcon : link.defaultIcon}
                                        alt={link.label}
                                        style={{ height: "30px" }}
                                    />

                                    <Box sx={{ marginLeft: "12px" }}>{link.label}</Box>
                                </ListItem>
                            </NavLink>
                        ))}
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                        backgroundColor: "#FBFBFB",
                    }}
                >
                    <Container sx={{ pt: 1 }}>
                        <Box sx={{ py: 10 }}>
                            <Outlet />
                        </Box>
                    </Container>
                </Box>
            </Box>
            {openDialogWorkSpace && (
                <Dialog
                    maxWidth="md"
                    open={openDialogWorkSpace}
                    onClose={() => {
                        setOpenDialogWorkspace(false);
                        setNewWorkspaceName("");
                    }}
                >
                    <DialogContent>
                        <TextField
                            fullWidth
                            label="Workspace Name"
                            value={newWorkspaceName}
                            onChange={(e) => setNewWorkspaceName(e.target.value)}
                            variant="outlined"
                            error={newWorkspaceName && newWorkspaceName.trim().length < 5}
                            helperText={
                                newWorkspaceName && newWorkspaceName.trim().length < 5
                                    ? "name must be at least 5 characters long"
                                    : ""
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenDialogWorkspace(false);
                                setNewWorkspaceName("");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit" onClick={handleSaveClick}>
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <SnackBar
                open={openSnackbar}
                message={openSnackbar.message}
                type={openSnackbar.type}
                onClose={() => setOpenSnackbar(false)}
            />
        </ThemeProvider>
    );
};

export default DashBoard;
