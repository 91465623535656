import React, { useState, useEffect, useContext } from "react";
import {
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Link,
    Grid,
    Box,
} from "@mui/material";
import logo from "../../../img/icons/xaudit1.png";
import { useNavigate } from "react-router-dom";
import cookie from "cookiejs";
import { AuthContext } from "../../Provider/AuthProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import SnackBar from "../../common/SnackBar";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WorkspaceSetup = () => {
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    useEffect(() => {
        if (!cookie.get("token")) {
            navigate("/login");
        }
    }, []);
    const [members, setMembers] = useState(context.savedMembers);
    const [workspace, setWorkSpace] = useState(
        context.workspacename
            ? context.workspacename
            : context?.user?.email
              ? `${context?.user?.email.split("@")[0]}'s workspace`
              : ""
    );
    const [notFilledError, setNotFilledError] = useState(false);
    const [notFilledMessage, setNotFilledMessage] = useState(
        "Ensure all fields are valid and emails aren't already used."
    );
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleAddMember = () => {
        const allFieldsFilled = members.every((obj) =>
            Object.values(obj).every(
                (value) =>
                    value !== "" &&
                    value !== null &&
                    value !== undefined &&
                    value !== "Enter valid email address" &&
                    value !== "Email Already Used"
            )
        );
        if (!allFieldsFilled) {
            setNotFilledError(true);
        } else if (members.length > 4) {
            setNotFilledError(true);
            setNotFilledMessage("Team invite cannot include more than 5 members.");
        } else {
            const newMember = {
                email: "",
                role: "",
            };
            setMembers([...members, newMember]);
        }
    };

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedMembers = [...members];

        if (name === "email") {
            const isValidEmail = emailRegex.test(value);
            const isPresent = isValidEmail ? members.find((member) => member.email === value) : undefined;
            if (isValidEmail && isPresent === undefined) {
                updatedMembers[index].email = value;
                updatedMembers[index].error = false;
            } else if (isPresent) {
                updatedMembers[index].email = value;
                updatedMembers[index].error = "Email Already Used";
            } else {
                updatedMembers[index].email = value;
                updatedMembers[index].error = "Enter valid email address";
            }
        } else if (name === "role") {
            if (value && value !== "") {
                updatedMembers[index].role = value.toLowerCase();
            } else {
                updatedMembers[index].role = value.toLowerCase();
                updatedMembers[index].error = "Role Must Have Value";
            }
        } else {
        }
        setMembers(updatedMembers);
    };
    const worskspaceApI = async () => {
        const filterWorkSpace =
            workspace && workspace.length > 5 ? workspace : `${context.user.email.split("@")[0]}'s workspace`;
        await fetch(`${REACT_APP_SERVER_URL}/createworkspace`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                name: filterWorkSpace,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data, "workspace response");
            });
    };

    const teamInviteApi = () => {
        fetch(`${REACT_APP_SERVER_URL}/sendTeamInvite`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspace: workspace,
                userEmail: Object.keys(context.user).length > 0 ? context.user.email : "",
                teams: members,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data, "invite response");
                return;
            });
    };
    const skipHandler = async (filterWorkSpace) => {
        worskspaceApI();
        navigate("/add-domain");
    };
    const handleInvite = () => {
        const checkLength =
            members.length - 1 === 0 &&
            members[members.length - 1].email === "" &&
            members[members.length - 1].role === "" &&
            !members[members.length - 1].error;
        if (checkLength) {
            worskspaceApI();

            navigate("/add-domain");
        } else {
            let error = false;
            members.forEach((member) => {
                if (member.error) {
                    error = member.error;
                }
                if (!member.email || member.email.trim() === "" || !member.role || member.role.trim() === "") {
                    error = "All fields must have value";
                }
            });
            if (error) {
                setNotFilledError(true);
                setNotFilledMessage(error);
            } else {
                teamInviteApi(members);
                worskspaceApI();

                navigate("/add-domain");
            }
        }
    };
    const handleDelete = (indexToDelete) => {
        const updatedMembers = members.filter((_, index) => index !== indexToDelete);
        setMembers(updatedMembers);
    };

    return (
        <Box>
            <Box component="img" src={logo} alt="Placeholder" sx={{ marginBottom: 2, width: "15%", height: "auto" }} />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center" }}>
                    Set up your workspace
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Choose a name for your workspace. This will help you and your team stay organized.
                </Typography>
                <TextField
                    label="Workspace Name"
                    defaultValue={workspace}
                    onChange={(e) => setWorkSpace(e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    style={{ maxWidth: "400px" }}
                />

                <Typography sx={{ fontSize: "30px", fontWeight: "700", textAlign: "center", marginTop: "20px" }}>
                    Invite team members
                </Typography>
                <Typography variant="body1">
                    Add your colleagues to collaborate with you. Enter their email addresses below, and they’ll receive
                    an invitation to join your workspace.
                </Typography>
                {members.map((member, index) => (
                    <Grid container spacing={2} key={index} style={{ marginTop: "10px", maxWidth: "600px" }}>
                        <Grid item xs={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={member.email}
                                onChange={(event) => handleInputChange(index, event)}
                                fullWidth
                                variant="outlined"
                                error={member.error}
                                helperText={member.error}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: "30px" }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Role</InputLabel>
                                <Select
                                    name="role"
                                    onChange={(event) => handleInputChange(index, event)}
                                    label="Role"
                                    value={member.role}
                                    renderValue={(selected) => {
                                        if (selected === "") return "";
                                        return selected; // Only display the role name
                                    }}
                                >
                                    <MenuItem value="Owner">
                                        <div>
                                            <Typography variant="body1">Owner</Typography>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                style={{ display: "block" }}
                                            >
                                                Owners have access to the entire account and all workspaces.
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="Member">
                                        <div>
                                            <Typography variant="body1">Member</Typography>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                style={{ display: "block" }}
                                            >
                                                Members only have access to this workspace.
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} sx={{ paddingTop: "30px!important" }}>
                            {index > 0 && (
                                <DeleteIcon
                                    sx={{ color: "#d10d0d", cursor: "pointer" }}
                                    onClick={() => handleDelete(index)}
                                />
                            )}
                        </Grid>
                    </Grid>
                ))}
                <Typography
                    variant="body2"
                    color="primary"
                    style={{ cursor: "pointer", width: "600px" }}
                    onClick={handleAddMember}
                >
                    + Add another member
                </Typography>

                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: "1rem", maxWidth: "400px" }}
                >
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleInvite}>
                            Next
                        </Button>
                    </Grid>
                    <Grid item>
                        <Link
                            underline="hover"
                            onClick={(e) => {
                                e.preventDefault();
                                skipHandler();
                            }}
                        >
                            Skip
                        </Link>
                    </Grid>
                </Grid>
                <SnackBar
                    open={notFilledError}
                    message={notFilledMessage}
                    type="error"
                    onClose={() => setNotFilledError(false)}
                />
            </Box>
        </Box>
    );
};

export default WorkspaceSetup;
