import React, { createContext, useState } from "react";
import jwtDecode from "jwt-decode";
import cookie from "cookiejs";
const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const isLogin = cookie.get("token");
    const loggeduser = isLogin ? jwtDecode(isLogin) : {};
    const [user, setUser] = useState(loggeduser);
    const [savedMembers, setSavedMembers] = useState([
        {
            email: "",
            role: "",
        },
    ]);
    const [workspacename, setworkspacename] = useState(
        loggeduser && loggeduser.email ? `${loggeduser.email.split("@")[0]}'s workspace` : ""
    );

    const handleuser = (value) => {
        setUser(value);
    };
    const addMembers = (members, name) => {
        setSavedMembers(
            members.length === 0
                ? [
                      {
                          email: "",
                          role: "",
                      },
                  ]
                : members
        );
        setworkspacename(name);
    };
    const authContextValue = {
        user,
        savedMembers,
        workspacename,
        handleuser,
        addMembers,
    };
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
