import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    MenuItem,
    Paper,
    Divider,
    Select,
    InputLabel,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Chip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { AuthContext } from "../Provider/AuthProvider";
import SnackBar from "../common/SnackBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WorkspaceManagement = ({ workSpaceData, handleCloseWorkSpace }) => {
    const ctx = useContext(AuthContext);

    const [isEditable, setIsEditable] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [workspaceName, setWorkspaceName] = useState(workSpaceData.name);
    const [newMemberEmail, setNewMemberEmail] = useState("");
    const [newMemberRole, setNewMemberRole] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [members, setMembers] = useState([]);
    const handleCancelClick = () => {
        setWorkspaceName(workSpaceData.name);
        setIsEditable(false);
    };
    const handleSaveClick = () => {
        if (!workspaceName && workspaceName.trim().length < 5) {
            setOpenSnackbar({ message: "Invalid WorkSpace Name", type: "error" });
            return;
        }
        fetch(`${REACT_APP_SERVER_URL}/updateWorkspaceName`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspaceName: workspaceName,
                workspaceId: workSpaceData.id,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    setOpenSnackbar({ message: data.message, type: "success" });
                } else {
                    setWorkspaceName(workSpaceData.name);
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
            });
        setIsEditable(false);
    };
    const handleEditClick = () => setIsEditable(true);
    const handleInvite = () => {
        const newMember = { email: newMemberEmail, role: newMemberRole.toLowerCase() };
        const isEmailPresent = members.some((member) => member.email === newMemberEmail);
        if (isEmailPresent) {
            setOpenSnackbar({ message: "Email Already Exists", type: "error" });
            setNewMemberEmail("");
            setNewMemberRole("");
            return;
        }
        if (members.length >= 5) {
            setOpenSnackbar({ message: "Team invite cannot include more than 5 members", type: "error" });
            setNewMemberEmail("");
            setNewMemberRole("");
        }
        fetch(`${REACT_APP_SERVER_URL}/sendTeamInvite`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                workspace: workspaceName,
                userEmail: Object.keys(ctx.user).length > 0 ? ctx.user.email : "",
                teams: [newMember],
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success && data.message === "Invite Sent Successfully") {
                    setOpenSnackbar({ message: data.message, type: "success" });
                    getTeamMembers();
                } else {
                    setOpenSnackbar({ message: data.message, type: "error" });
                }
            });
        setNewMemberEmail("");
        setNewMemberRole("");
    };

    const handleDelete = (id) => {
        fetch(`${REACT_APP_SERVER_URL}/removeTeamMember/${id}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.success) {
                    getTeamMembers();
                    setOpenSnackbar({ message: "Delete SuccessFully", type: "success" });
                } else {
                    setOpenSnackbar({ message: "Delete Failed", type: "error" });
                }

                setOpenDeleteDialog(false);
            });
    };
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setNewMemberEmail(value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValidEmail(emailRegex.test(value));
    };
    const handleBackClick = () => {
        handleCloseWorkSpace();
    };
    const getTeamMembers = () => {
        fetch(`${REACT_APP_SERVER_URL}/fetchWorkspaceMembers/${workSpaceData.id}`, {
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            method: "GET",
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.data) {
                    setMembers(data.data);
                }
            });
    };
    useEffect(() => {
        getTeamMembers();
    }, []);

    return (
        <Box>
            <Box sx={{ padding: 4 }}>
                <ArrowBackIcon onClick={handleBackClick} sx={{ marginBottom: 2 }} />

                <Paper elevation={1} sx={{ padding: 3, mb: 3 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Workspace
                    </Typography>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={12} sm={8}>
                            <TextField
                                onClick={handleEditClick}
                                fullWidth
                                label="Workspace Name"
                                value={workspaceName}
                                onChange={(e) => setWorkspaceName(e.target.value)}
                                variant="outlined"
                                InputProps={{ readOnly: !isEditable }}
                                error={workspaceName && workspaceName.trim().length < 5}
                                helperText={
                                    workspaceName && workspaceName.trim().length < 5
                                        ? "name must be at least 5 characters long"
                                        : ""
                                }
                            />
                        </Grid>
                    </Grid>
                    {isEditable && (
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleSaveClick} sx={{ mr: 2 }}>
                                Save
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Box>
                    )}
                </Paper>
                <Paper elevation={1} sx={{ padding: 3, mb: 3 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Members
                    </Typography>
                    <Grid container alignItems="flex-start" spacing={3}>
                        <Grid item xs={12} sm={8} md={8}>
                            <TextField
                                fullWidth
                                label="Enter email address"
                                value={newMemberEmail}
                                onChange={handleEmailChange}
                                variant="outlined"
                                error={!isValidEmail && !!newMemberEmail}
                                helperText={!isValidEmail && newMemberEmail ? "Enter a valid email address" : ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>Role</InputLabel>
                                <Select
                                    name="role"
                                    label="Role"
                                    value={newMemberRole}
                                    onChange={(e) => setNewMemberRole(e.target.value)}
                                    renderValue={(selected) => selected || ""}
                                >
                                    <MenuItem value="Owner">
                                        <div>
                                            <Typography variant="body1">Owner</Typography>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                style={{ display: "block" }}
                                            >
                                                Owners have access to the entire account and all workspaces.
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                    <MenuItem value="Member">
                                        <div>
                                            <Typography variant="body1">Member</Typography>
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                style={{ display: "block" }}
                                            >
                                                Members only have access to this workspace.
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleInvite}
                                disabled={!newMemberEmail || !newMemberRole || !isValidEmail}
                            >
                                Invite
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper elevation={1} sx={{ padding: 3 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Team Members
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {members.length === 0 ? (
                        "No Team Members Found"
                    ) : (
                        <>
                            {members.map((member) => (
                                <Grid key={member.email} container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                                    <Grid item md={8} sm={4}>
                                        <Typography variant="body1">{member.name}</Typography>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Typography variant="body2" color="textSecondary">
                                                    {member.email}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Chip
                                                    label={member.status === 1 ? "Active" : "Pending"} // Role can be displayed as the chip label
                                                    size="small"
                                                    color={member.status === 1 ? "primary" : "default"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={3} sm={3}>
                                        <Button fullWidth variant="outlined" disabled style={{ textAlign: "center" }}>
                                            {member.role}
                                        </Button>
                                    </Grid>
                                    <Grid item md={1} sm={1}>
                                        {member.email !== ctx.user.email && (
                                            <Delete
                                                onClick={() => setOpenDeleteDialog({ id: member.id })}
                                                sx={{ color: "#d10d0d", cursor: "pointer" }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    )}
                </Paper>
                {openDeleteDialog && (
                    <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                        <DialogContent>
                            <DialogContentText>Are you sure you want to delete ?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                            <Button onClick={() => handleDelete(openDeleteDialog.id)}>Delete</Button>
                        </DialogActions>
                    </Dialog>
                )}
                <SnackBar
                    open={openSnackbar}
                    message={openSnackbar.message}
                    type={openSnackbar.type}
                    onClose={() => setOpenSnackbar(false)}
                />
            </Box>
        </Box>
    );
};

export default WorkspaceManagement;
